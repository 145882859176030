import React, { useState } from "react"
import { Pagination, Col } from "antd"
import { Document, Page } from "react-pdf/dist/umd/entry.webpack"
import { Empty } from "lib"

const PdfDisplayBox = ({ attachment, isLoading }) => {
  const [total, setTotal] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotal(numPages)
  }
  const onChange = page => {
    setPageNumber(page)
  }

  return (
    <Col xl={17} xs={24}>
      <Document
        file={attachment?.data}
        onLoadSuccess={onDocumentLoadSuccess}
        renderMode="svg"
        className="pdf-view"
        noData={<Empty />}
      >
        <Page
          pageNumber={pageNumber}
          scale={1}
          style={{
            border: "1px solid black",
            width: "fit-content",
            margin: "auto",
          }}
        />
      </Document>
      <Pagination
        current={pageNumber}
        pageSize={1}
        total={total}
        onChange={onChange}
        showSizeChanger={false}
        className="pdf-view"
      />
    </Col>
  )
}

export default PdfDisplayBox
