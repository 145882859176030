import React, { useState, useEffect, useRef } from "react"
import {
  Card,
  Space,
  Form,
  Button,
  Upload,
  Row,
  Col,
  Divider,
  Modal,
  Spin,
  message,
  Popconfirm,
  Typography,
  Input,
} from "antd"
import { downloadData, showError } from "@action"
import "@style/frontline.less"
import {
  RedoOutlined,
  ExclamationCircleOutlined,
  CheckCircleTwoTone,
  UndoOutlined,
} from "@ant-design/icons"
import FormInputNumber from "./FormInputNumber"
import { AppraisalClient, UploadAppraisalTaskClient } from "@api"
import moment from "moment"
import ReturnModal from "./ReturnModal"
import ScoreDescription from "./ScoreDescription"
import { FormattedMessage, useIntl } from "react-intl"
import PathTable from "./PathTable"
import RemarkTable from "./RemarkTable"
import UploadTasksTable from "./UploadTasksTable"
import PdfDisplayBox from "./PdfDisplayBox"
import { FilledDivider, DeleteButton } from "lib"

const PdfView = React.memo(
  ({
    data,
    refresh,
    refreshScore,
    afterSubmit,
    degree360Weighting,
    coreCompetencyWeighting,
    siteKPIWeighting,
  }) => {
    const {
      appraisalId,
      appraisal,
      template,
      isHaveReturn,
      currentStep,
      isLastStep,
      hasEdit,
    } = data
    const inputValueRef = useRef("")
    const [isLoading, setIsLoading] = useState(false)
    const [saveTime, setSaveTime] = useState(null)
    const [visible, setVisible] = useState(false)
    const [pdfStatus, setPdfStatus] = useState({
      isLoading: false,
      attachment: null,
    })
    const [form] = Form.useForm()
    const { Text } = Typography

    const { formatMessage } = useIntl()
    const uploadSuccessMsg = formatMessage({ id: "uploadSuccess" })
    const uploadPdfHelper = formatMessage({ id: "uploadPdfHelper" })
    const fileLimitMsg = formatMessage({ id: "fileLimit" }, { size: "20" })
    const actionMsg = formatMessage({
      id: appraisal?.currentStep?.action ?? "Submit",
    })
    const cancelMsg = formatMessage({ id: "cancel" })

    const confirmToMsg = formatMessage(
      { id: "confirmTo" },
      { action: actionMsg }
    )
    const commentPlaceholder = formatMessage({ id: "textAreaReviewComment" })
    const lastSubmitWarning = formatMessage({ id: "lastSubmitWarning" })

    const colProps = { xl: 24, xs: 8 }

    const handleUpload = async ({ file }) => {
      try {
        setIsLoading(true)
        const client = new AppraisalClient()
        await client.uploadPDF(appraisalId, {
          data: file,
          fileName: file.name,
        })
        message.success(uploadSuccessMsg)
        refresh()
      } catch (err) {
        showError(err)
      } finally {
        setIsLoading(false)
      }
    }

    const beforeUpload = (file, fileSize = 10) => {
      const isSizeValid = file.size / 1024 / 1024 < fileSize
      if (!isSizeValid) {
        message.error(fileLimitMsg)
        return false
      }
      if (file.type !== "application/pdf") {
        message.error(uploadPdfHelper)
        return false
      }
      return file
    }

    const submitForm = async value => {
      const client = new AppraisalClient()
      try {
        setIsLoading(true)
        await client.edit(appraisalId, value)
        message.success("Submit Success")
        afterSubmit()
      } catch (err) {
        console.log(err)
        showError(err)
        setIsLoading(false)
      }
    }
    const onFinish = () => {
      Modal.confirm({
        title: confirmToMsg,
        okText: actionMsg,
        cancelText: cancelMsg,
        content: (
          <>
            {isLastStep && <Text type="danger">{lastSubmitWarning}</Text>}
            {appraisal?.currentStep?.isNeedReviewComment && (
              <Input.TextArea
                placeholder={commentPlaceholder}
                onChange={e => {
                  inputValueRef.current = e?.target?.value
                }}
                autoSize={{ minRows: 5, maxRows: 10 }}
              />
            )}
          </>
        ),
        icon: <ExclamationCircleOutlined />,
        centered: true,
        onOk: async () => {
          submitForm({
            id: appraisalId,
            isNext: true,
            comment: inputValueRef.current,
          })
          inputValueRef.current = ""
        },
      })
    }
    const handleRecall = async id => {
      const client = new AppraisalClient()
      try {
        setIsLoading(true)
        await client.recall(id)
        message.success("Recall Appraisal success !")
        afterSubmit()
      } catch (err) {
        console.log(err)
        message.error(err)
      } finally {
        setIsLoading(false)
      }
    }

    const handleSave = () => {
      setSaveTime({
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("HH:mm:ss"),
      })
      refreshScore()
    }

    const handleDownload = () => {
      downloadData(pdfStatus.attachment)
    }

    const handleExport = async () => {
      try {
        setIsLoading(false)
        const client = new AppraisalClient()
        const result = await client.exportFrontlineAppraisal(appraisalId)
        downloadData(result)
      } catch (err) {
        showError(err)
      } finally {
        setIsLoading(false)
      }
    }
    
    
    const handleDeletePdf = async () => {
      try {
        setIsLoading(true)
        const client = new AppraisalClient()
        await client.deletePDF(appraisalId)
      } catch (err) {
        showError(err)
      } finally {
        refresh()
        setIsLoading(false)
      }
    }

    const handleScan = async ({ file }) => {
      try {
        setIsLoading(true)
        const client = new UploadAppraisalTaskClient()
        await client.uploadFrontline(
          appraisal?.appraisal.appraisalCycle.id,
          appraisal?.appraisal.siteOrDepartmentId,
          appraisalId,
          {
            data: file,
            fileName: file.name,
          }
        )
        message.success(uploadSuccessMsg)
      } catch (err) {
        showError(err)
      } finally {
        setIsLoading(false)
      }
    }

    useEffect(() => {
      let mountRef = true
      const getAttachment = async id => {
        if (!mountRef) return
        setPdfStatus({ attachment: null, isLoading: true })
        const client = new AppraisalClient()
        const attachment = await client.downloadPDF(id).catch(err => {
          return null
        })

        if (!mountRef) return
        setPdfStatus({ attachment: attachment, isLoading: false })
      }

      if (hasEdit) form.validateFields()
      if (!!appraisalId) getAttachment(appraisalId)

      return () => (mountRef = false)
    }, [appraisalId, hasEdit])

    return (
      <Card
        title={`${appraisal?.appraisal.name} ${appraisal?.appraisal.staffNo}`}
        extra={
                <Space>
                  {hasEdit && (
                    <Button
                      type="primary"
                      shape="round"
                      onClick={handleExport}
                      loading={isLoading}
                    >
                      <FormattedMessage id="export" />
                    </Button>
                  )}
                  <Button
                    type="primary"
                    shape="round"
                    onClick={handleDownload}
                    ghost
                    loading={isLoading}
                    disabled={!pdfStatus.attachment}
                  >
                    <FormattedMessage id="printAsPDF" />
                  </Button>
                  {hasEdit && (
                    <Space>
                        <Upload
                          name="file"
                          maxCount={1}
                          accept=".pdf"
                          showUploadList={false}
                          beforeUpload={file => beforeUpload(file, 200)}
                          customRequest={handleScan}
                        >
                          <Button shape="round">
                            <FormattedMessage id="scan" />
                          </Button>
                        </Upload>
                    </Space>
                  )}
            {refresh && (
              <Button
                shape="round"
                type="text"
                icon={<RedoOutlined />}
                onClick={refresh}
              />
            )}
                </Space>
        }
      >
        <Spin spinning={isLoading} size="large">
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <UploadTasksTable
                params={{
                  appraisalId: appraisalId,
                }}
              />
            </Col>
            <PdfDisplayBox {...pdfStatus} />
            <Col xl={7} xs={24}>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                scrollToFirstError={{
                  behavior: "smooth",
                  block: "center",
                }}
              >
                <Row>
                  <Col span={24}>
                    <Divider orientation="left">
                      <FormattedMessage id="score" />
                    </Divider>
                  </Col>
                  <Col span={24}>
                    <FilledDivider>
                      <FormattedMessage id="coreCompetencies" />
                    </FilledDivider>
                  </Col>

                  {template.ratingList["core"].map((item, index) => (
                    <Col {...colProps}>
                      <FormInputNumber
                        index={index + 1}
                        currentStep={currentStep}
                        onSave={handleSave}
                        appraisalId={appraisalId}
                        key={item.id}
                        {...item}
                        hasEdit={hasEdit}
                      />
                    </Col>
                  ))}

                  <Col span={24}>
                    <FilledDivider>
                      <FormattedMessage id="additionalScore" />
                    </FilledDivider>
                  </Col>
                  {template.ratingList["score"].map((item, index) => (
                    <Col {...colProps}>
                      <FormInputNumber
                        index={index + 1}
                        currentStep={currentStep}
                        onSave={handleSave}
                        appraisalId={appraisalId}
                        key={item.id}
                        {...item}
                        hasEdit={hasEdit}
                      />
                    </Col>
                  ))}

                  {hasEdit && (
                    <Col {...colProps}>
                      <Form.Item>
                        {saveTime ? (
                          <FormattedMessage
                            id="lastSavedOn"
                            values={saveTime}
                          />
                        ) : null}
                        &nbsp;
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                      </Form.Item>
                    </Col>
                  )}
                  {hasEdit && (
                    <Col {...colProps}>
                      <Form.Item
                        extra={
                          <Space direction="vertical">
                            <FormattedMessage id="uploadPdfHelper" />
                            <FormattedMessage id="supportDocReminder" />
                          </Space>
                        }
                      >
                        <Upload
                          name="file"
                          maxCount={1}
                          accept=".pdf"
                          showUploadList={false}
                          beforeUpload={file => beforeUpload(file, 10)}
                          customRequest={handleUpload}
                        >
                          <Button shape="round">
                            <FormattedMessage id="uploadSupportingDocuments" />
                          </Button>
                        </Upload>
                        &nbsp;
                      </Form.Item>
                      <DeleteButton
                          isTranslate={true}
                          type="primary"
                          shape="round"
                          onClick={handleDeletePdf}
                          ghost
                          loading={isLoading}
                          disabled={!pdfStatus.attachment}
                          size="Middle"
                      >
                          <FormattedMessage id="deletePdf" />
                      </DeleteButton>
                    </Col>
                  )}
                </Row>
              </Form>
            </Col>

            <Col span={24}>
              <Divider orientation="left">
                <FormattedMessage id="appraisalHistory" />
              </Divider>
              <RemarkTable remark={appraisal?.appraisal.appraisalHistory} />
            </Col>
            <Col span={24}>
              <Divider orientation="left">
                <FormattedMessage id="approvalPath" />
              </Divider>
              <PathTable id={appraisalId} />
            </Col>
            <Col span={24}>
              <ScoreDescription
                degree360Weighting={degree360Weighting}
                coreCompetencyWeighting={coreCompetencyWeighting}
                siteKPIWeighting={siteKPIWeighting}
                score={data.score}
              />
            </Col>
            <Col flex="auto" />
            {!hasEdit && appraisal?.hasRecall && (
              <Popconfirm
                title={<FormattedMessage id="confirmRecall" />}
                okText={<FormattedMessage id="ok" />}
                cancelText={<FormattedMessage id="cancel" />}
                onConfirm={() => {
                  handleRecall(appraisalId)
                }}
              >
                <Button shape="round" icon={<UndoOutlined />}>
                  <FormattedMessage id="recall" />
                </Button>
              </Popconfirm>
            )}
            {isHaveReturn && hasEdit && (
              <Col>
                <Button
                  type="danger"
                  shape="round"
                  loading={isLoading}
                  onClick={() => {
                    setVisible(true)
                  }}
                >
                  <FormattedMessage id="returnv2" />
                </Button>
              </Col>
            )}

            {hasEdit && (
              <Col>
                <Button
                  type="primary"
                  shape="round"
                  loading={isLoading}
                  disabled={!pdfStatus.attachment}
                  onClick={() => {
                    form.submit()
                  }}
                >
                  <FormattedMessage
                    id={appraisal?.currentStep?.action ?? "Submit"}
                  />
                </Button>
              </Col>
            )}
          </Row>
          <ReturnModal
            appraisalId={appraisalId}
            submitForm={submitForm}
            visible={visible}
            handleCancel={() => {
              setVisible(false)
            }}
          />
        </Spin>
      </Card>
    )
  }
)

export default PdfView
