import React, { useReducer, useEffect, useCallback, useContext } from "react"
import {
  Row,
  Col,
  Divider,
  Table,
  Button,
  Spin,
  Tooltip,
  Checkbox,
  Space,
  Modal,
  message,
} from "antd"
import {
  AppraisalClient,
  CoreCompetencyDefinitionSetClient,
  AppraisalTemplateClient,
} from "@api"
import { showError, getScoreByStep } from "@action"
import PdfView from "./PdfView"
import { FormattedMessage } from "react-intl"
import UploadTasksTable from "./UploadTasksTable"
import { RedoOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import { ContextStore } from "@context"
import { ScoreProgressCircle } from "lib"

const FormContent = ({
  onChange,
  dataSource,
  pageMeta,
  hasBatchApprove,
  params,
  fetchData,
  sortTable,
}) => {
  const initialState = {
    data: {
      appraisalId: null,
      appraisal: null,
      template: {
        ratingList: {
          core: [],
          score: [],
        },
      },
      attachment: null,
      isHaveReturn: false,
      hasEdit: false,
    },
    selectedKeys: [],
    activeKey: null,
    isLoading: false,
  }
  const { locale } = useContext(ContextStore)

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case "data":
        return { ...state, data: payload, isLoading: false}

      case "refreshScore":
        return {
          ...state,
          data: {
            ...state.data,
            score: payload,
          },
        }
      case "initForm":
        return { ...state, activeKey: payload, selectedKeys: [] }

      case "activeKey":
        return { ...state, activeKey: payload }

      case "isLoading":
        return { ...state, isLoading: payload }

      case "selectedKeys":
        return {
          ...state,
          selectedKeys: payload,
        }

      default:
        break
    }
  }

  const [{ data, activeKey, isLoading, selectedKeys }, dispatch] = useReducer(
    reducer,
    initialState
  )

  const columns = [
    {
      dataIndex: "appraisalNumber",
      key: "staffName",
      sortOrder:
        params.orderBy === "staffName"
          ? params.isAscend
            ? "ascend"
            : "descend"
          : false,
      title: (
        <div>
          <FormattedMessage id="staffName" />, <FormattedMessage id="staffNo" />
        </div>
      ),
      render: (_, { staffNo, staffName }) => (
        <div style={{ color: "#958655", cursor: "pointer", fontSize: "14px" }}>
          <div>{`${staffName} ${staffNo}`}</div>
        </div>
      ),
      sorter: true,
    },
    {
      title: <FormattedMessage id="finalScore2" values={{ name: "" }} />,
      key: "finalScore",
      dataIndex: "finalScore",
      sortOrder:
        params.orderBy === "finalScore"
          ? params.isAscend
            ? "ascend"
            : "descend"
          : false,
      sorter: true,
      render: (finalScore, { scoreJSON, id }) => (
        <ScoreProgressCircle
          finalScore={finalScore}
          scoreJSON={scoreJSON}
          appraisalId={id}
        />
      ),
    },
  ]

  const rowSelection = {
    onChange: (_, selectedRows) => {
      const newKeys = selectedRows
        .filter(record => record.hasBatchApprove)
        .map(record => record.id)
      dispatch({ type: "selectedKeys", payload: newKeys })
    },
    fixed: true,
    renderCell: (_, record, index, originNode) =>
      record.hasBatchApprove ? (
        originNode
      ) : (
        <Tooltip
          title={<FormattedMessage id="cannotBatchApprove" />}
          placement="right"
        >
          <Checkbox disabled />
        </Tooltip>
      ),
  }

  const refreshScore = async id => {
    try {
      const client = new AppraisalClient()
      const score = await client.getScore(id)
      dispatch({
        type: "refreshScore",
        payload: score,
      })
    } catch (err) {
      showError(err)
    }
  }

  const getAppraisal = useCallback(() => {
    const callAPI = async id => {
      if (!id) return
      try {
        dispatch({ type: "isLoading", payload: true })
        const client = new AppraisalClient()
        const templateClient = new AppraisalTemplateClient()
        const appraisalContent = await client.get2(id)
        const score = await client.getScore(id)
        const templateResult = await templateClient.get2(
          appraisalContent.content.appraisal.appraisalTemplateId
        )
        const coreClient = new CoreCompetencyDefinitionSetClient()
        const coreResult = await coreClient.get2(
          templateResult.coreCompetencyDefinitionSetId
        )

        const currentStep = appraisalContent.content.currentStep

        const coreCompetencyList = coreResult.coreCompetencyDefinitions.map(
          core => {
            return {
              item: { ...core, minScore: 0, maxScore: core.score },
              history: getScoreByStep(
                appraisalContent.content.appraisal.appraisalCoreCompetency,
                "coreCompetencyDefinitionId",
                core.coreCompetencyDefinitionId,
                currentStep?.step
              ),
              isCore: true,
            }
          }
        )

        const scoreAdjustmentList = coreResult.scoreAdjustmentDefinitions.map(
          score => {
            return {
              item: score,
              history: getScoreByStep(
                appraisalContent.content.appraisal.appraisalScoreAdjustment,
                "scoreAdjustmentDefinitionId",
                score.scoreAdjustmentDefinitionId,
                currentStep?.step
              ),
              isCore: false,
            }
          }
        )

        dispatch({
          type: "data",
          payload: {
            appraisalId: appraisalContent.content.appraisal.id,
            appraisal: appraisalContent.content,
            score: score,
            hasEdit: appraisalContent.content.hasEdit,
            hasRecall: appraisalContent.content.hasRecall,
            isHaveReturn: appraisalContent.content.currentStep?.isHaveReturn,
            isLastStep: currentStep?.step >= appraisalContent.content.lastStep,
            template: {
              ...templateResult,
              ratingList: {
                core: coreCompetencyList,
                score: scoreAdjustmentList,
              },
            },
            currentStep: currentStep?.step,
          },
        })
      } catch (err) {
        showError(err)
        dispatch({ type: " isLoading", payload: false })
      }
    }
    callAPI(activeKey)
  }, [activeKey])

  const batchApprove = useCallback(() => {
    const callAPI = async keys => {
      try {
        dispatch({ type: "isLoading", payload: true })
        const client = new AppraisalClient()
        await client.batchApprove({ comment: "", id: keys })
        message.success(
          locale.includes("zh") ? "批量審批成功" : "Batch Approve Success"
        )
        onChange(1)
      } catch (err) {
        showError(err)
      } finally {
        dispatch({ type: "isLoading", payload: false })
      }
    }
    const confirmText = locale.includes("zh")
      ? { title: "確認審批已選擇的評估？", okText: "審批", cancelText: "取消" }
      : {
          title: "Confirm to approve selected appraisals ?",
          okText: "Approve",
          cancelText: "Cancel",
        }

    Modal.confirm({
      ...confirmText,
      icon: <ExclamationCircleOutlined />,
      centered: true,
      onOk() {
        callAPI(selectedKeys)
      },
    })
  }, [selectedKeys])

  const handelChange = (pagination, b, sorter, { action }) => {
    switch (action) {
      case "paginate":
        onChange(pagination.current)
        break

      case "sort":
        if (!sorter.order) {
          sortTable("staffName", true)
          break
        }
        sortTable(sorter.columnKey, sorter.order !== "descend")
        break

      default:
        break
    }
  }

  useEffect(() => {
    if (dataSource.length)
      dispatch({ type: "initForm", payload: dataSource[0].id })
  }, [dataSource])

  useEffect(() => {
    getAppraisal()
  }, [getAppraisal])

  return (
    <Row gutter={[24, 24]} justify="center">
      <Col span={24}>
        <Divider orientation="left">
          <FormattedMessage id="appraisalList" />
        </Divider>
        <UploadTasksTable params={params} />
      </Col>
      <Col xl={6} xs={24}>
        <Table
          title={() => (
            <Space>
              {hasBatchApprove && (
                <Button
                  type="primary"
                  shape="round"
                  disabled={!selectedKeys.length}
                  loading={isLoading}
                  onClick={() => {
                    batchApprove()
                  }}
                >
                  <FormattedMessage id="batchApprove" />
                </Button>
              )}
              <Button
                shape="round"
                type="text"
                icon={<RedoOutlined />}
                onClick={fetchData}
              />
            </Space>
          )}
          onRow={(record, rowIndex) => ({
            onClick: event =>
              dispatch({ type: "activeKey", payload: record.id }),
          })}
          rowKey="id"
          size="small"
          showHeader={hasBatchApprove}
          rowSelection={
            hasBatchApprove
              ? {
                  selectedRowKeys: selectedKeys,
                  ...rowSelection,
                }
              : false
          }
          bordered={false}
          onChange={handelChange}
          dataSource={dataSource}
          columns={columns}
          pagination={{
            ...pageMeta,
            position: ["topCenter"],
            showSizeChanger: false,
          }}
        />
      </Col>

      <Col xl={18} xs={24}>
        {!isLoading ? (
          <PdfView
            refreshScore={() => refreshScore(activeKey)}
            data={data}
            coreCompetencyWeighting={data.template.coreCompetencyWeighting}
            degree360Weighting={data.template.degree360Weighting}
            siteKPIWeighting={data.template.siteKPIWeighting}
            refresh={() => {
              getAppraisal()
            }}
            afterSubmit={() => {
              onChange(1)
            }}
          />
        ) : (
          <Spin
            spinning
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        )}
      </Col>
    </Row>
  )
}

export default FormContent
