import locale from "antd/es/date-picker/locale/en_US"

export const showSelectProps = {
  dropdownMatchSelectWidth: false,
  showSearch: true,
  filterOption: (input, option) =>
    "label" in option
      ? option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      : option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
}

export const dataPickerProp = {
  locale: locale,
  format: process.env.DATE_FORMAT,
}

export const uploadProps = {
  listType: "picture",
  maxCount: 1,
  beforeUpload: () => false,
  accept:
    "image/*, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet ,	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
}

export const uploadHelper =
  "檔案不能大過10 Mbs,亦只接受圖片,PDF和一般Office檔案格式"

export const uploadExcelHelper = "檔案不能大過10 Mbs,亦只接受Excel檔案格式"
