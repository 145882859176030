import React, { useEffect, useState, useCallback, useRef } from "react"
import { Table, Button, Divider } from "antd"
import { FormattedMessage } from "react-intl"
import { transformTime, countPages } from "@action"
import { UploadAppraisalTaskClient } from "@api"
import { UploadStatusTag } from "lib"
import { RedoOutlined } from "@ant-design/icons"
import CancelTaskModal from "./CancelTaskModal"

const UploadTasksTable = React.memo(({ params }) => {
  const mountRef = useRef(true)
  const [dataSource, setDataSource] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState({
    visible: false,
    taskId: null,
  })
  const columns = [
    {
      title: <FormattedMessage id="uploadTime" />,
      dataIndex: "created",
      render: date => transformTime(date),
    },
    {
      title: <FormattedMessage id="startTime" />,
      dataIndex: "startTime",
      render: date => transformTime(date),
    },
    {
      title: <FormattedMessage id="endTime" />,
      dataIndex: "endTime",
      render: date => transformTime(date),
    },
    {
      title: <FormattedMessage id="site" />,
      dataIndex: "siteOrDepartmentName",
    },

    {
      title: <FormattedMessage id="status" />,
      dataIndex: "status",
      render: status => <UploadStatusTag value={status} />,
    },
    // {
    //   title: <FormattedMessage id="action" />,
    //   dataIndex: "id",
    //   render: (id, record) =>
    //     record.status === 0 && (
    //       <Button
    //         size="small"
    //         shape="round"
    //         icon={<DeleteOutlined />}
    //         onClick={() => {
    //           setModal({
    //             visible: true,
    //             taskId: id,
    //           })
    //         }}
    //       >
    //         <FormattedMessage id="cancel" />
    //       </Button>
    //     ),
    // },
  ]

  const fetchData = useCallback(() => {
    const callAPI = async params => {
      if (
        (!params.cycleId || !params.siteOrDepartmentId) &&
        !params.appraisalId
      ) {
        if (!mountRef.current) return
        setDataSource({})
        return
      }
      if (!mountRef.current) return
      setIsLoading(true)
      const client = new UploadAppraisalTaskClient()
      const result = await client
        .get(params.cycleId, params.siteOrDepartmentId, params.appraisalId)
        .catch(err => {
          console.log(err)
          return []
        })
      if (!mountRef.current) return
      setDataSource(result)
      setIsLoading(false)
    }
    callAPI(params)
  }, [params])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    return () => (mountRef.current = false)
  }, [])

  return dataSource.length ? (
    <div>
      {!!params.appraisalId && (
        <Divider orientation="left">
          <FormattedMessage id="scanningProgress" />
        </Divider>
      )}
      <Table
        title={() =>
          !params.appraisalId && (
            <div>
              <FormattedMessage id="scanningProgress" />
              <Button
                shape="round"
                type="text"
                loading={isLoading}
                icon={<RedoOutlined />}
                onClick={() => fetchData()}
              />
            </div>
          )
        }
        expandable={{
          expandedRowRender: record => {
            const result = countPages(record.response)
            return (
              <div>
                {record.taskException && (
                  <p style={{ margin: 0 }}>{record.taskException}</p>
                )}
                {result && (
                  <p style={{ margin: 0 }}>
                    <FormattedMessage
                      id="unrecognizedPages"
                      values={{ pages: result }}
                    />
                  </p>
                )}
              </div>
            )
          },
          rowExpandable: record =>
            record.taskException || countPages(record.response),
        }}
        size="small"
        rowKey="id"
        pagination={false}
        loading={isLoading}
        columns={columns}
        dataSource={dataSource}
        scroll={{ y: 150 }}
      />
      {!!params.appraisalId && <Divider />}

      <CancelTaskModal
        {...modal}
        handleCancel={() => {
          setModal({
            visible: false,
            taskId: null,
          })
        }}
        refresh={() => fetchData()}
      />
    </div>
  ) : null
})

export default UploadTasksTable
