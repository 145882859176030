import React from "react"
import { Table } from "antd"
import { FormattedMessage } from "react-intl"
import { transformDate } from "@action"

const RemarkTable = React.memo(({ remark }) => {
  const columns = [
    {
      title: <FormattedMessage id="date" />,
      dataIndex: "created",
      render: text => transformDate(text),
    },
    {
      title: <FormattedMessage id="status" />,
      dataIndex: "status",
    },
    { title: <FormattedMessage id="staffName" />, dataIndex: "staff" },
    { title: <FormattedMessage id="action" />, dataIndex: "content" },
  ]

  return (
    <Table
      size="small"
      rowKey="id"
      pagination={false}
      columns={columns}
      dataSource={remark}
      expandable={{
        expandedRowRender: record => (
          <pre style={{ margin: 0 }}>{record.remarks} </pre>
        ),
        rowExpandable: record => !!record.remarks,
      }}
      scroll={{ y: 300 }}
    />
  )
})

export default RemarkTable
