import React, { useReducer, useEffect, useCallback } from "react"
import Head from "@components/Head"
import MainLayout from "@components/main_layout"
import {
  Typography,
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  Upload,
  message,
  Space,
  Divider,
  Tooltip,
  Grid,
} from "antd"
import { Empty } from "lib"
import { QuestionCircleTwoTone } from "@ant-design/icons"
import "@style/CycleTableTemplate.less"
import { showSelectProps } from "@object"
import { showError } from "@action"
import {
  OtherClient,
  AppraisalCycleClient,
  AppraisalClient,
  UploadAppraisalTaskClient,
} from "@api"
import { FormContent } from "@components/Frontline"
import { downloadData } from "@action"
import { FormattedMessage, useIntl } from "react-intl"

const FrontlinePage = ({ location }) => {
  const { xl } = Grid.useBreakpoint()
  const [form] = Form.useForm()
  const { Option } = Select
  const { Title } = Typography
  const colProps = { span: 8 }

  const { formatMessage } = useIntl()

  const uploadSuccessMsg = formatMessage({ id: "uploadSuccess" })
  const yearOfAppraisalPlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: formatMessage({ id: "yearOfAppraisal" }) }
  )
  const sitePlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: formatMessage({ id: "site" }) }
  )

  const staffNamePlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    { name: formatMessage({ id: "staffName" }) }
  )

  const staffNoPlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    { name: formatMessage({ id: "staffNo" }) }
  )

  const employmentStatusPlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: formatMessage({ id: "staffStatus" }) }
  )

  const approvePlaceholder = formatMessage(
    {
      id: "pleaseSelect",
    },
    {
      name: "",
    }
  )

  const initialState = {
    options: {
      siteOrDepartmentList: [],
      codeList: [],
    },
    isRefresh: true,
    dataSource: [],
    pageMeta: { current: 1, totalCount: 0, pageSize: 10 },
    isLoading: false,
    hasFilter: false,
    canFetchData: false,

    params: {
      siteOrDepartmentId: null,
      appraisalNumber: null,
      cycleId: null,
      pageIndex: 1,
      orderBy: "staffName",
      isAscend: true,
      hasBatchApprove: null,
      isActive: true,
      hasPDF: null,
      isStaffInactiveExcluded: false,
      isStaffActiveExcluded: false,
      isStaffDisabledExcluded: false,
    },
  }
  const reducer = (state, { type, payload }) => {
    switch (type) {
      case "params":
        return {
          ...state,
          params: {
            ...payload,
            orderBy: "staffName",
            isAscend: true,
            pageIndex: 1,
          },
          hasFilter: !!payload.cycleId && !!payload.siteOrDepartmentId,
        }

      case "pageChange":
        return {
          ...state,
          params: { ...state.params, pageIndex: payload },
        }

      case "options":
        return {
          ...state,
          options: payload,
        }
      case "isLoading":
        return {
          ...state,
          isLoading: payload,
        }
      case "initPage":
        return {
          ...state,
          options: payload.options,
          params: {
            ...initialState.params,
            cycleId: payload.initialCycleId,
            siteOrDepartmentId: payload.initialSiteOrDepartmentId,
          },
          canFetchData: true,
        }

      case "hasFilter":
        return {
          ...state,
          hasFilter: payload,
        }

      case "loadData":
        return {
          ...state,
          hasBatchApprove: payload.hasBatchApprove,
          dataSource: payload.dataSource,
          pageMeta: payload.pageMeta,
          isLoading: false,
          isRefresh: true,
        }

      case "sort":
        return {
          ...state,
          params: {
            ...state.params,
            ...payload,
          },
        }

      case "clear":
        return {
          ...state,
          hasFilter: false,
          dataSource: initialState.dataSource,
          pageMeta: initialState.pageMeta,
          isSearch: false,
        }

      default:
        break
    }
  }
  const [
    {
      dataSource,
      pageMeta,
      isLoading,
      options,
      params,
      hasBatchApprove,
      hasFilter,
      canFetchData,
    },
    dispatch,
  ] = useReducer(reducer, initialState)

  const fetchData = useCallback(() => {
    const callAPI = async params => {
      dispatch({ type: "isLoading", payload: true })
      const client = new AppraisalClient()
      const result = await client
        .get(
          params.cycleId,
          params.isActive,
          params.appraisalNumber,
          params.staffNo,
          params.staffName,
          null,
          [params.siteOrDepartmentId],
          null,
          params.orderBy,
          params.isAscend,
          10,
          params.pageIndex,
          true,
          params.hasBatchApprove,
          params.hasPDF,
          params.isStaffInactiveExcluded,
          params.isStaffActiveExcluded,
          params.isStaffDisabledExcluded
        )
        .catch(err => {
          console.log(err)
          return {
            items: [],
            pageIndex: 1,
            total: 0,
            pageSize: 10,
          }
        })
      dispatch({
        type: "loadData",
        payload: {
          dataSource: result.items,
          hasBatchApprove: params.hasBatchApprove !== false && params.isActive,
          pageMeta: {
            current: result.pageIndex,
            total: result.totalCount,
            pageSize: 10,
          },
        },
      })
    }
    if (canFetchData) callAPI(params)
  }, [canFetchData, params])

  const handleUpload = async ({ file }) => {
    try {
      dispatch({ type: "isLoading", payload: true })

      const client = new UploadAppraisalTaskClient()
      await client.uploadFrontline(
        params.cycleId,
        params.siteOrDepartmentId,
        null,
        {
          data: file,
          fileName: file.name,
        }
      )
      message.success(uploadSuccessMsg)
    } catch (err) {
      showError(err)
    } finally {
      dispatch({ type: "isLoading", payload: false })
    }
  }

  const beforeUpload = async file => {
    const formValidate = await form.validateFields().catch(err => null)
    const isSizeValid = file.size / 1024 / 1024 < 200
    if (!isSizeValid) {
      message.error("File cannot not larger than 200Mbs")
    }

    if (formValidate && isSizeValid) return file
    return false
  }

  const handleExport = async () => {
    if (!params.cycleId || !params.siteOrDepartmentId) return
    dispatch({ type: "isLoading", payload: true })
    const client = new AppraisalClient()
    const code = options.codeList.find(
      option => option.id === params.cycleId
    ).code
    const [result, isSuccess] = await client
      .frontListForm(code, params.siteOrDepartmentId)
      .then(result => [result, true])
      .catch(err => {
        console.log(err)
        return [null, false]
      })
    if (isSuccess) downloadData(result)
    dispatch({ type: "isLoading", payload: false })
  }

  const handleChange = page => {
    dispatch({ type: "pageChange", payload: page })
  }

  const sortTable = (field, isAscend) => {
    dispatch({
      type: "sort",
      payload: {
        orderBy: field,
        isAscend: isAscend,
      },
    })
  }

  const onFinish = values => {
    let isStaffActiveExcluded = false
    let isStaffDisabledExcluded = false
    let isStaffInactiveExcluded = false
    if (values.staffStatus !== null) {
      if (values.staffStatus === "active") {
        isStaffActiveExcluded = false
        isStaffDisabledExcluded = true
        isStaffInactiveExcluded = true
      }
      if (values.staffStatus === "inactive") {
        isStaffInactiveExcluded = false
        isStaffActiveExcluded = true
        isStaffDisabledExcluded = true
      }
      if (values.staffStatus === "terminated") {
        isStaffDisabledExcluded = false
        isStaffActiveExcluded = true
        isStaffInactiveExcluded = true
      }
    }
    const newValues = {
      ...values,
      isStaffInactiveExcluded,
      isStaffActiveExcluded,
      isStaffDisabledExcluded,
    }
    dispatch({ type: "params", payload: newValues })
  }

  const reset = () => {
    form.resetFields()
    form.submit()
  }

  useEffect(() => {
    const GetOptions = async queryString => {
      const params = new URLSearchParams(queryString)
      const initialCycleId = params.get("cycleId")
      const initialSiteOrDepartmentId = params.get("siteOrDepartmentId")

      const otherClient = new OtherClient()
      const options = await otherClient.getSiteOrDepartment().catch(err => {
        console.log(err)
        return []
      })
      const cycleClient = new AppraisalCycleClient()
      const codeList = await cycleClient.getDropdown().catch(err => {
        console.log(err)
        return []
      })

      const [initialCycle = null] = codeList
      dispatch({
        type: "initPage",
        payload: {
          options: { siteOrDepartmentList: options, codeList: codeList },
          initialCycleId: initialCycleId ?? initialCycle?.id,
          initialSiteOrDepartmentId: initialSiteOrDepartmentId,
        },
      })
      form.setFieldsValue({
        cycleId: initialCycleId ? +initialCycleId : initialCycle?.id,
        siteOrDepartmentId: initialSiteOrDepartmentId
          ? +initialSiteOrDepartmentId
          : null,
      })
    }

    GetOptions(location.search)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <MainLayout>
      <Head id="frontline" />
      <Title level={3}>
        <FormattedMessage id="frontline" />
      </Title>
      <Form form={form} onFinish={onFinish} layout={xl ? "inline" : "vertical"}>
        <Row gutter={[12, 12]} className="report_table" justify="center">
          <Col {...colProps}>
            <Form.Item
              name="cycleId"
              label={<FormattedMessage id="yearOfAppraisal" />}
            >
              <Select
                {...showSelectProps}
                placeholder={yearOfAppraisalPlaceholder}
                allowClear
                options={options.codeList.map(option => ({
                  label: option.code,
                  value: option.id,
                }))}
              />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              name="siteOrDepartmentId"
              label={<FormattedMessage id="site" />}
            >
              <Select
                {...showSelectProps}
                placeholder={sitePlaceholder}
                allowClear
                options={options.siteOrDepartmentList.map(option => ({
                  label: option.name,
                  value: option.id,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              extra={<FormattedMessage id="MicroSoftWordDescription" />}
            >
              <Space>
                <Button
                  type="primary"
                  shape="round"
                  onClick={handleExport}
                  loading={isLoading}
                  disabled={!hasFilter}
                >
                  <FormattedMessage id="batchExport" />
                </Button>
                <Upload
                  name="file"
                  maxCount={1}
                  accept=".pdf"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  customRequest={handleUpload}
                >
                  <Button
                    shape="round"
                    loading={isLoading}
                    disabled={!hasFilter}
                  >
                    <FormattedMessage id="batchUpload" />
                  </Button>
                </Upload>
                <Tooltip title={<FormattedMessage id="hasFilterDescription" />}>
                  <QuestionCircleTwoTone />
                </Tooltip>
              </Space>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider style={{ margin: "0px" }} orientation="left">
              <FormattedMessage id="appraiseeInfo" />
            </Divider>
          </Col>
          <Col {...colProps}>
            <Form.Item
              name="staffName"
              label={<FormattedMessage id="staffName" />}
            >
              <Input placeholder={staffNamePlaceholder} />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item name="staffNo" label={<FormattedMessage id="staffNo" />}>
              <Input placeholder={staffNoPlaceholder} />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              name="staffStatus"
              label={<FormattedMessage id="staffStatus" />}
            >
              <Select allowClear placeholder={employmentStatusPlaceholder}>
                <Option key="active" value="active">
                  <FormattedMessage id="active" />
                </Option>
                <Option key={"inactive"} value="inactive">
                  <FormattedMessage id="inactive" />
                </Option>
                <Option key="terminated" value="terminated">
                  <FormattedMessage id="terminated" />
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col {...colProps} />
          <Col span={24}>
            <Divider orientation="left" style={{ margin: "0px" }}>
              <FormattedMessage id="appraisalStatus" />
            </Divider>
          </Col>
          <Col {...colProps}>
            <Form.Item
              name="isActive"
              label={<FormattedMessage id="status" />}
              initialValue={true}
            >
              <Select
                options={[
                  {
                    label: <FormattedMessage id="pending" />,
                    value: true,
                  },
                  {
                    label: <FormattedMessage id="submitted" />,
                    value: false,
                  },
                ]}
              />
            </Form.Item>
          </Col>

          <Col {...colProps}>
            <Form.Item noStyle dependencies={["isActive"]}>
              {({ getFieldValue }) =>
                getFieldValue("isActive") ? (
                  <Form.Item
                    name="hasBatchApprove"
                    label={<FormattedMessage id="readyToSubmit" />}
                  >
                    <Select
                      placeholder={approvePlaceholder}
                      allowClear
                      options={[
                        {
                          label: <FormattedMessage id="yes" />,
                          value: true,
                        },
                        {
                          label: <FormattedMessage id="no" />,
                          value: false,
                        },
                      ]}
                    />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Col>

          <Form.Item noStyle dependencies={["hasBatchApprove", "isActive"]}>
            {({ getFieldValue }) =>
              getFieldValue("isActive") &&
              getFieldValue("hasBatchApprove") === false ? (
                <Col {...colProps}>
                  <Form.Item
                    name="hasPDF"
                    label={<FormattedMessage id="hasPDF" />}
                  >
                    <Select
                      placeholder={approvePlaceholder}
                      allowClear
                      options={[
                        {
                          label: <FormattedMessage id="yes" />,
                          value: true,
                        },
                        {
                          label: <FormattedMessage id="no" />,
                          value: false,
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              ) : (
                <Col {...colProps} />
              )
            }
          </Form.Item>

          <Col>
            <Form.Item noStyle>
              <Button
                shape="round"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                <FormattedMessage id="search" />
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Button shape="round" onClick={reset} disabled={isLoading}>
              <FormattedMessage id="clear" />
            </Button>
          </Col>
        </Row>
      </Form>

      {dataSource.length ? (
        !isLoading && (
          <FormContent
            params={params}
            dataSource={dataSource}
            hasBatchApprove={hasBatchApprove}
            pageMeta={pageMeta}
            onChange={handleChange}
            sortTable={sortTable}
            fetchData={fetchData}
          />
        )
      ) : (
        <Empty />
      )}
    </MainLayout>
  )
}

export default FrontlinePage
