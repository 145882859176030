import React from "react"
import Seo from "@components/seo"
import { useIntl } from "react-intl"
const Head = ({ id }) => {
  const { formatMessage } = useIntl()
  const text = formatMessage({ id: id })
  return <Seo title={text} />
}

export default Head
