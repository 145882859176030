import React, { useState } from "react"
import { Modal, Form, Input, Typography } from "antd"
import { showError } from "@action"
import { ExclamationCircleTwoTone } from "@ant-design/icons"
import { FormattedMessage, useIntl } from "react-intl"

const ReturnModal = ({ visible, handleCancel, appraisalId, submitForm }) => {
  const { formatMessage } = useIntl()
  const [form] = Form.useForm()
  const { TextArea } = Input
  const { Text } = Typography
  const [isLoading, setIsLoading] = useState(false)
  const returnAlert = formatMessage({ id: "frontlineReturnAlert" })

  const onFinish = async values => {
    try {
      setIsLoading(true)
      await submitForm({
        id: appraisalId,
        isNext: false,
        comment: values.comment,
      })
    } catch (err) {
      showError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      title={
        <>
          <ExclamationCircleTwoTone twoToneColor="red" />
          &nbsp; <FormattedMessage id="returnModal" />
        </>
      }
      centered
      destroyOnClose={true}
      visible={visible}
      okText={<FormattedMessage id="returnv2" />}
      cancelText={<FormattedMessage id="cancel" />}
      onCancel={handleCancel}
      onOk={() => form.submit()}
      confirmLoading={isLoading}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item>
          <div>
            <Text>{returnAlert}</Text>
          </div>
        </Form.Item>

        <Form.Item
          name="comment"
          rules={[
            {
              required: true,
              message: "Please write down your comment before return",
            },
          ]}
        >
          <TextArea
            disabled={isLoading}
            placeholder={formatMessage({ id: "returnReason" })}
            autoSize={{ minRows: 5, maxRows: 10 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ReturnModal
