import React, { useState } from "react"
import { Modal, Form, Input } from "antd"
import { showError } from "@action"
import { UploadAppraisalTaskClient } from "@api"
import { FormattedMessage, useIntl } from "react-intl"

const CancelTaskModal = ({ visible, handleCancel, taskId, refresh }) => {
  const { formatMessage } = useIntl()
  const [form] = Form.useForm()
  const { TextArea } = Input
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = async values => {
    try {
      setIsLoading(true)
      const client = new UploadAppraisalTaskClient()
      await client.update(taskId, {
        id: taskId,
        ...values,
      })
      handleCancel()
      refresh()
    } catch (err) {
      showError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      title={<FormattedMessage id="cancelTask" />}
      centered
      destroyOnClose={true}
      visible={visible}
      okText={<FormattedMessage id="submit" />}
      cancelText={<FormattedMessage id="cancel" />}
      onCancel={handleCancel}
      onOk={() => form.submit()}
      confirmLoading={isLoading}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="remarks">
          <TextArea
            disabled={isLoading}
            placeholder={formatMessage({ id: "cancelReason" })}
            autoSize={{ minRows: 5, maxRows: 10 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CancelTaskModal
