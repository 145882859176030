import React, { useState, useContext } from "react"
import { Form, InputNumber, Typography } from "antd"
import { translate, showError } from "@action"
import {
  AppraisalCoreCompetencyClient,
  AppraisalScoreAdjustmentClient,
} from "@api"
import { ContextStore } from "@context"
import { useIntl } from "react-intl"

const FormInputNumber = React.memo(
  ({
    appraisalId,
    item,
    history,
    hasEdit,
    isCore,
    onSave,
    currentStep,
    index,
  }) => {
    const { locale } = useContext(ContextStore)
    const { formatMessage } = useIntl()
    const inputWarning = formatMessage(
      { id: "pleaseInput" },
      { name: formatMessage({ id: "score" }) }
    )
    const [isLoading, setIsLoading] = useState(false)
    const [record, setRecord] = useState(history ?? null)

    const handleOnBlur = async value => {
      try {
        if (!value && value !== 0) return
        setIsLoading(true)

        const isCreate = !record || record.step !== currentStep
        if (isCore) {
          const client = new AppraisalCoreCompetencyClient()

          if (isCreate) {
            const result = await client.create(
              null,
              item.coreCompetencyDefinitionId,
              appraisalId,
              value,
              []
            )
            setRecord({ id: result.id, step: currentStep })
          } else {
            await client.update(record.id, null, value, [], [])
          }
        } else {
          const client = new AppraisalScoreAdjustmentClient()
          if (isCreate) {
            const result = await client.create(
              1,
              "frontline",
              null,
              null,
              item.scoreAdjustmentDefinitionId,
              appraisalId,
              value,
              null
            )
            setRecord({ id: result.content.id, step: currentStep })
          } else {
            await client.update(
              record.id,
              "frontline",
              null,
              value,
              null,
              [],
              []
            )
          }
        }
        onSave()
      } catch (err) {
        showError(err)
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <Form.Item
        label={
          isCore
            ? `${index}. ${translate(item.name, locale)}`
            : `${translate(item.name, locale)}`
        }
        required
      >
        {hasEdit ? (
          <Form.Item
            name={
              isCore
                ? `core${item.coreCompetencyDefinitionId}`
                : `score${item.scoreAdjustmentDefinitionId}`
            }
            rules={[{ required: true, message: inputWarning }]}
            initialValue={history?.rating}
            noStyle
          >
            <InputNumber
              onBlur={e => {
                let newRating = +e.target.value
                if (newRating > item.maxScore) {
                  newRating = item.maxScore
                }
                if (newRating < item.minScore) {
                  newRating = item.minScore
                }
                handleOnBlur(newRating)
              }}
              min={item.minScore}
              max={item.maxScore}
              disabled={isLoading}
            />
          </Form.Item>
        ) : (
          history?.rating
        )}
        {isCore && <Typography.Text strong> / {item.maxScore}</Typography.Text>}
      </Form.Item>
    )
  }
)

export default FormInputNumber
