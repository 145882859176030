import React, { useContext } from "react"
import { Descriptions, Divider, Row, Col } from "antd"
import "@style/current_score.less"
import { translate } from "@action"
import { FormattedMessage } from "react-intl"
import { ContextStore } from "@context"

const ScoreDescription = ({
  score,
  coreCompetencyWeighting,
  siteKPIWeighting,
}) => {
  const { locale } = useContext(ContextStore)
  return (
    <div className="current-score">
      <Row className="score-row">
        <Divider orientation="left">
          <FormattedMessage id="finalScore2" values={{ name: "" }} />
        </Divider>
        <Col span={7}>
          <Descriptions
            title={
              <>
                <FormattedMessage id="coreCompetencies" />
                {" (" + coreCompetencyWeighting + "%)"}
              </>
            }
            column={1}
          >
            <Descriptions.Item label={<FormattedMessage id="score" />}>
              {score
                ? score.coreCompetencyScore +
                  " / " +
                  score.coreCompetencyMaxScore
                : null}
            </Descriptions.Item>
            {score &&
              score.scoreAdjustmentGroup.map((item, index) => (
                <Descriptions.Item
                  key={index}
                  label={translate(item.name, locale)}
                >
                  {item.totalScore}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </Col>
        <Col span={1}>
          <Divider type="vertical" plain className="info-divider" />
        </Col>

        {score && (score.siteKPIMaxScore > 0 || score.isSiteKPIPending) && (
          <>
            <Col span={7}>
              <Descriptions
                title={
                  <>
                    <FormattedMessage id="siteKPI" />
                    {" (" + siteKPIWeighting + "%)"}
                  </>
                }
                column={1}
              >
                <Descriptions.Item label={<FormattedMessage id="score" />}>
                  {!score.isSiteKPIPending ? (
                    score.siteKPIScore + " / " + score.siteKPIMaxScore
                  ) : (
                    <FormattedMessage id="tbc" />
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Col>

            <Col span={1}>
              <Divider type="vertical" className="info-divider" plain />
            </Col>
          </>
        )}
      </Row>
      <Row className="score-row">
        <Col span={7}>
          <Descriptions column={1}>
            <Descriptions.Item
              className="sub-score"
              label={<FormattedMessage id="subtotal" />}
            >
              {score?.coreCompetencySubtotal}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={1}>
          <Divider type="vertical" plain className="info-divider" />
        </Col>
        {score && (score.siteKPIMaxScore > 0 || score.isSiteKPIPending) && (
          <>
            <Col span={7}>
              <Descriptions column={1}>
                <Descriptions.Item
                  className="sub-score"
                  label={<FormattedMessage id="subtotal" />}
                >
                  {score.siteKPIMaxScore > 0 ? score.siteKPISubtotal : null}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={1}>
              <Divider type="vertical" plain className="info-divider" />
            </Col>
          </>
        )}

        <Divider orientation="right" className="score-divider">
          <FormattedMessage
            id="finalScore2"
            values={{ name: `: ${score?.finalScore}` }}
          />
        </Divider>
      </Row>
    </div>
  )
}

export default ScoreDescription
